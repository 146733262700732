.App {
    text-align: center;
  }
  
  .admin-container {
    margin-top: 20px;
    padding: 20px;
    background-color: #f7f7f7;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .admin-container ul {
    list-style: none;
    padding: 0;
  }
  
  .admin-container li {
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  
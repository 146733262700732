.app-bar {
  background-color: #001F3F; /* Navy blue color */
  padding: 10px;
  display: flex;
  justify-content: space-between; /* Align items to both ends */
  align-items: center;
  color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.app-bar-content {
  display: flex;
  align-items: center;
  width: 100%; /* Ensures full width for proper spacing */
}

.app-logo {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.app-title {
  flex-grow: 1; /* Allows title to take available space */
}

.app-title h1 {
  margin: 0;
  font-size: 24px;
}

.app-title p {
  margin: 0;
  font-size: 14px;
  color: #e0e0e0; /* Light gray for subtitle */
}

.help-icon {
  color: white;
  font-size: 24px; /* Adjust icon size */
  cursor: pointer;
  margin-left: 10px; /* Space between title and help icon */
  transition: color 0.3s;
}

.help-icon:hover {
  color: #ffcc00; /* Change color on hover */
}

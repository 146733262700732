/* Home Background */
.home-bg {
  background-image: url('../assets/medical-bg.jpg'); /* Your medical background image */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  padding: 20px;
}

/* Glass effect for sections */
.glass-box {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 20px;
  color: black;
  margin: 0 auto;
  max-width: 800px;
}


